import React, { useEffect, useState } from 'react'
import { getEntrepriseByCode } from '../../../Services/Pointeuse/EntrepriseApi';
import { calcul_total_rub, formatFloat, formatNChiffres, nombreDeMoisEntreDeuxDates } from '../../../Utils/helper';

export default function Fichepaie({ t, employeeData ,societe,prime}) {
      
    

    return (
        <div  style={{backgroundColor:'white' ,paddingTop:'50px' , paddingBottom:'50px'}}   id='content'>
            <div  >
                <div className=" ml-8  mr-8 mt-5  "  >
                    <div className=" hr-attlist  ml-8  mr-8 mt-5"  >
                        <table className="table table-color table-vcenter text-nowrap" id="hr-attendance">

                            <tbody className='col-12'>
                                <tr >
                                    <th className='col-6'><h1>{societe?.nom}</h1></th>
                                    <td className='col-6'><h1>Bulletin de paie</h1></td>
                                </tr>
                            </tbody>


                        </table>
                        <table className="table table-color table-vcenter text-nowrap" id="hr-attendance">

                            <tbody className='col-12'>
                                <tr >
                                    <th className='col-9'><p>Employeur affilié à la  CNSS sous le n°</p></th>
                                    <td className='col-3'>{societe?.num_affiliation}</td>
                                </tr>
                            </tbody>


                        </table>
                        <table className="table table-color table-vcenter text-nowrap" id="hr-attendance">

                            <tbody className='col-12'>
                                <tr >
                                    <th className='col-3'><p>{t('Matricule')}</p></th>
                                    <td className='col-3'>{employeeData?.matricule_user &&employeeData?.matricule_user ? employeeData?.matricule_user?.toString().padStart(4, "0") :employeeData?.user_id?.toString().padStart(4, "0")}</td>
                                    <th className='col-3'><p>{t('Mois')} </p></th>
                                    <td className='col-3'>{employeeData?.mois}</td>
                                </tr>
                                <tr >
                                    <th className='col-3'><p>{t('N° CNSS')}</p></th>
                                    <td className='col-3'>{employeeData?.cnss}</td>
                                    <th className='col-3'><p>{t('Situation de Famille')}</p></th>
                                    <td className='col-3'>{employeeData?.situation_famille}</td>
                                </tr>
                                <tr >
                                    <th className='col-3'><p>{t('Nom')}</p></th>
                                    <td className='col-3'>{employeeData?.nom}</td>
                                    <th className='col-3'><p>{t('Catégorie')}</p></th>
                                    <td className='col-3'>{employeeData?.categorie}</td>
                                </tr>
                                <tr >
                                    <th className='col-3'><p>{t('Prénom')}</p></th>
                                    <td className='col-3'>{employeeData?.prenom}</td>
                                    <th className='col-3'><p>{('Echelle')}</p></th>
                                    <td className='col-3'>{employeeData?.echelle}</td>
                                </tr>
                                <tr >
                                    <th className='col-3'><p>{t('Qualification')}</p></th>
                                    <td className='col-3'>{employeeData?.Qualification==='gerant' ? 'Directeur général adjoint':employeeData?.Qualification}</td>
                                    <th className='col-3'><p>{t('Echelon')}</p></th>
                                    <td className='col-3'>{employeeData?.echelon}</td>
                                </tr>
                            </tbody>


                        </table>
                        <table className="table table-color table-vcenter text-nowrap" id="hr-attendance">

                            <thead>
                                <th>{t('CA')}</th>
                                <th>{t('CB')}</th>
                                <th>{t('FM')}</th>
                                <th>{t('JF')}</th>
                                <th>{t('AB')}</th>
                                <th>{t('PR')}</th>
                                <th>{t('JT')}</th>
                                <th>{t('NH')}</th>
                                <th>{t('TH')}</th>

                            </thead>
                            <tbody>
                                <td></td>
                                <td>{employeeData?.CB}</td>
                                <td>{employeeData?.FM}</td>
                                <td>{employeeData?.JF}</td>
                                <td>{employeeData?.AB}</td>
                                <td>{employeeData?.PR}</td>
                                <td>{employeeData?.JT}</td>
                                <td>{employeeData?.NH}</td>
                                <td>{employeeData?.TH}</td>
                            </tbody>


                        </table>


                        <table className="table table-color table-vcenter text-nowrap" id="hr-attendance">
                            <thead>

                                <th className='col-2'>{t('Code')}</th>
                                <th className='col-3'>{t('Désignation')}</th>
                                <th className='col-2'>{t('Nbre')}</th>
                                <th className='col-2'>{t('Montants')}</th>
                                <th className='col-2'>{t('Retenues')}</th>

                            </thead>
                            <tbody>
                                <tr >
                                    <td className='col-2'></td>
                                    <td className='col-3'>{(prime !== null && !prime?.moisplus) ? (t('Prime')):t('Salaire de Base')}</td>
                                    <td className='col-2'></td>
                                    <td className='col-2'>{employeeData?.salaire_base}</td>
                                    <td className='col-2'></td>
                                </tr>
                                {employeeData?.rubs_indemnite && employeeData.rubs_indemnite.length !== 0 && employeeData.rubs_indemnite.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.code}</td>
                                        <td>{item?.libelle}</td>
                                        <td>{item?.presence === 1 ? employeeData.total : null}</td>
                                        <td>{item?.presence === 1 ?formatFloat(parseFloat(item.valeur_mensuel) * employeeData.total ) : formatFloat(item.valeur_mensuel)}</td>
                                        <td></td>
                                    </tr>
                                ))}
                                {employeeData?.avantages && employeeData.avantages.length !== 0 && employeeData.avantages.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.code}</td>
                                        <td>{item.libelle}</td>
                                        <td>{item.presence === 1 ? employeeData.total : null}</td>
                                        <td>{item?.presence === 1 ? formatFloat(parseFloat(item.valeur_mensuel) * employeeData.total) : formatFloat(item.valeur_mensuel)}</td>
                                        <td></td>
                                    </tr>
                                ))}
                                <tr >
                                    <td></td>
                                    <td>{t('Retenue Absence')}</td>
                                    <td>{formatFloat(employeeData?.AB)}</td>
                                    <td></td>
                                    <td>{formatFloat(employeeData?.salaire_retenue)}</td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><strong>{t('Total Brut')}</strong></td>
                                    <td></td>
                                    <td><strong>{formatFloat(employeeData?.total_brut)}</strong></td>
                                    <td></td>

                                </tr>
                                {employeeData?.rubs_retenue && employeeData.rubs_retenue.length !== 0 && employeeData.rubs_retenue.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.code}</td>
                                        <td>{item.libelle}</td>
                                        <td>{item.presence === 1 ? formatFloat(employeeData.total) : null}</td>
                                        <td></td>
                                        <td>{item?.presence === 1 ?formatFloat(parseFloat(item.valeur_mensuel) * employeeData.total ) : formatFloat(item.valeur_mensuel)}</td>

                                    </tr>
                                ))}
                                <tr >
                                    <td></td>
                                    <td>{t('CNSS')}</td>
                                    <td></td>
                                    <td></td>
                                    <td><strong>{formatFloat(employeeData?.rs_cnss)}</strong></td>


                                </tr>
                                <tr >
                                    <td></td>
                                    <td>{t('Salaire Imposable')}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{formatFloat(employeeData?.salaire_imposable)}</td>


                                </tr>
                                <tr >
                                    <td></td>
                                    <td>{t('IRPP')}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{formatFloat(employeeData?.calcul_irpp_mois)}</td>


                                </tr>
                                <tr >
                                    <td></td>
                                    <td>{t('CSS')}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{formatFloat(employeeData?.unp)}</td>


                                </tr>
                                {employeeData?.rubs_retenue && employeeData.rubs_retenue.length !== 0 && employeeData.rubs_retenue.map((item, index) => (
                                <tr key={index}>
                                    <td></td>
                                    <td>{item.libelle}</td>
                                    <td>{item.presence === 1 ? employeeData.total : null}</td>
                                    <td></td>
                                    
                                    <td>{formatFloat(calcul_total_rub(item.valeur_mensuel , item?.presence , employeeData.total ,item.valeur_annuel  ,nombreDeMoisEntreDeuxDates(item?.date_debut, item?.date_fin)))}</td>


                                    <td></td>
                                    <td></td>

                                </tr>
                            ))}
                                <tr >
                                    <td></td>
                                    <td><strong>{t('ToTal Retenue')}</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td><strong>{formatNChiffres(employeeData?.total_retenue||0,3)}</strong></td>


                                </tr>
                                <tr >
                                    <td></td>
                                    <td><strong>{t('Net a payer')}</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td><strong>{employeeData?.net_a_payer}</strong></td>


                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-color  border_table_bottom table-vcenter text-nowrap" id="hr-attendance">

                            <tbody className='col-12'>
                                <tr >
                                    <th className='col-10' style={{ paddingLeft: '50%' }}><h1>Net A PAYER</h1></th>
                                    <td className='col-2'><h2>{employeeData?.net_a_payer}</h2></td>
                                </tr>
                            </tbody>


                        </table>

                        <div className='row ml-8'>
                            <p className='col-2'>Legende :</p>
                            <div className='col-10'>
                                CA : conge annuel - CS : congés spéciaux - FM : formation - JF : jours fériés   <br/>
                                AB : abscnces - PR: présence - JT : jours de travail - NH : nombre d'heures  <br/> TH : taux horaire

                            </div>
                        </div>
                        <table className="table table-color table-vcenter text-nowrap" id="hr-attendance">

                            <tbody className='col-12'>
                                <tr >
                                    <th className='col-5' ><p>Je soussigné(e) déclare avoir reçu de mon employeur ,<br/> le montant du salaire ci-dessus détailllé ainsi <br/>que l'orginal de ce bulletin de paie</p></th>
                                    <td className='col-4'><h1>Bond de Caisse</h1></td>
                                </tr>
                            </tbody>


                        </table>
                        <table className="table table-color border_table_bottom table-vcenter text-nowrap" id="hr-attendance">

                            <thead>
                                <th> Matriclue </th>
                                <th>  Periode</th>
                                <th>  Net a payer</th>
                                <th>  Emargement</th>


                            </thead>
                            <tbody>
                                <td style={{ height: "50px" }}> {employeeData?.matricule_user &&employeeData?.matricule_user !== null ? employeeData?.matricule_user?.toString().padStart(4, "0") :employeeData?.user_id?.toString().padStart(4, "0")}</td>
                                <td>{employeeData?.mois}</td>
                                <td>{employeeData?.net_a_payer}</td>
                                <td></td>

                            </tbody>


                        </table>

                    </div>
                </div>
            </div>
        </div >
    );
}
/*<div className="card-body">
            <div className="card">
                <div className="row ml-5  mt-5">
                    <div className="table-responsive hr-attlist">
                        <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">

                            <tbody>
                                <tr>
                                    <th>Matricule</th>
                                    <td>{employeeData.matricule}</td>
                                </tr>
                                <tr>
                                    <th>N° CNSS</th>
                                    <td>{employeeData.cnss}</td>
                                </tr>
                                <tr>
                                    <th>Nom</th>
                                    <td>{employeeData.nom}</td>
                                </tr>
                                <tr>
                                    <th>Prénom</th>
                                    <td>{employeeData.prenom}</td>
                                </tr>
                                <tr>
                                    <th>Qualification</th>
                                    <td>{employeeData.qualification}</td>
                                </tr>
                                <tr>
                                    <th>Mois</th>
                                    <td>{employeeData.mois}</td>
                                </tr>
                                <tr>
                                    <th>Situation de Famille</th>
                                    <td>{employeeData.situationDeFamille}</td>
                                </tr>
                                <tr>
                                    <th>Catégorie</th>
                                    <td>{employeeData.categorie}</td>
                                </tr>
                                <tr>
                                    <th>Echelle</th>
                                    <td>{employeeData.echelle}</td>
                                </tr>
                                <tr>
                                    <th>Echelon</th>
                                    <td>{employeeData.echelon}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Désignation</th>
                                    <th>Nbre</th>
                                    <th>Montants</th>
                                    <th>Retenues</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salaryData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.code}</td>
                                        <td>{item.designation}</td>
                                        <td>{item.nbre}</td>
                                        <td>{item.montants}</td>
                                        <td>{item.retenues}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>*/